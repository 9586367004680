import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-printer',
  templateUrl: './printer.component.html',
  styleUrls: ['./printer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PrinterComponent implements OnInit {

  ticket: any;
  template: string;
  originalTemplate: string;
  printerBatch: any;
  interval: any;
  _pid: string;
  flag: any;
  showQR: boolean;
  qrCode: string;

  constructor(private db: AngularFirestore, private cd: ChangeDetectorRef, private route: ActivatedRoute) {
    this.ticket = {};
    this._pid = "";
    this.template = ""
    this.showQR = false;
    this.printerBatch = [];
    this.flag = false;
    this.qrCode = "";

    this.route.params.subscribe(params => {
      this._pid = params._pid;
    });
  }

  ngOnInit() {

    this.db.doc(`templates/badge_print`)
    .get().toPromise().then(data => {
      this.template = data.data().html;
      this.originalTemplate = data.data().html;
      this.cd.detectChanges();
    });

    this.db.collection('goPrint').ref
    .where('printerID', '==', this._pid)
    .onSnapshot(querySnapshot => {
      let docs = querySnapshot.docChanges();
      docs.forEach(async change => {
        if (docs.length === 1) {
          if (change.type === 'added') {
            this.ticket = change.doc.data();
            console.log(this.ticket);
            this.printerBatch.push(this.ticket);
            this.cd.detectChanges();
          }
        }
      });
    });

    this.interval = setInterval(() => {
      let itemPrint;

      if (itemPrint = this.printerBatch.pop()) {
        this.template = this.originalTemplate;
        this.showQR = true;
        this.qrCode = itemPrint.uidQR;

        this.template = this.template.replace("{{ticket.pName}}", itemPrint.pName ? itemPrint.pName : "");
        this.template = this.template.replace("{{ticket.pCargo}}", itemPrint.pCargo ? itemPrint.pCargo : "");
        this.template = this.template.replace("{{ticket.pCompany}}", itemPrint.pCompany ? itemPrint.pCompany : "");
        this.template = this.template.replace("{{ticket.ticketType}}", itemPrint.ticketType ? itemPrint.ticketType : "");

        this.cd.detectChanges();

        setTimeout(() => {
          window.print();
        }, 500);
      }
    }, 2000);
  }
}
